import React from 'react';
import './App.css';
import Home from './Home';
import Legal from './Legal';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div style={{ minHeight: "100vh", width: '100%' }}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mentions-legales" element={<Legal />} />
          <Route path="*" element={<Home/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;