import React, { useState , useEffect} from 'react';
import { ShopOutlined,TikTokFilled,CloseOutlined, MenuOutlined,InstagramFilled ,TranslationOutlined, PhoneOutlined, StarOutlined,MailOutlined, StarFilled, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import quote from './quote.png'
import emailjs from '@emailjs/browser';
import Carousel from './component/Carousel';
import Logo from './Logo.png'
import img1 from './images/1.JPG'
import img2 from './images/2.JPG'
import img4 from './images/4.JPG'
import img5 from './images/5.JPG'
import img6 from './images/6.JPG'
import img7 from './images/7.JPG'
import img8 from './images/8.JPG'
import img13 from './images/13.JPG'
import img14 from './images/14.JPG'
import img17 from './images/17.JPG'
import img16 from './images/16.JPG'
import img19 from './images/19.jpg'
import img20 from './images/20.JPG'
import img21 from './images/21.JPG'
import img22 from './images/22.JPG'
import img23 from './images/23.JPG'
import img24 from './images/24.JPG'
import img25 from './images/25.JPG'
import img26 from './images/26.JPG'
import img27 from './images/27.JPG'
import img28 from './images/28.JPG'


import basket from './icons/basket.png'
import catering from './icons/catering.png'
import fastdelivery from './icons/fast-delivery.png'
import planning from './icons/planning.png'
import menu from './icons/menu.png'
import wine from './icons/wine-tasting.png'
import food from './icons/food-delivery.png'



function Home() {

  const images = [
    {
      img:img25,
      description: '',
    },
    {
      img:img26,
      description: '',
    },
    {
      img:img20,
      description: 'Coin marié à louer à partir de 350€',
    },
    {
      img:img21,
      description: 'Coin marié à louer à partir de 80€',
    },
    {
      img:img16,
      description: 'Coin Photo à partir de 250€',
    },
    {
      img:img1,
      description: '',
    },
    {
      img:img2,
      description: '',
    },
    {
      img:img4,
      description: '',
    },
    {
      img:img8,
      description: '',
    },
    {
      img:img22,
      description: '',
    },
    {
      img:img23,
      description: '',
    },
    {
      img:img24,
      description: '',
    }
  ];
  
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [sendM, setSendM] = useState('Envoyer');


  const openCarousel = (index) => {
    setStartIndex(index);
    setIsCarouselOpen(true);
  };


  const closeCarousel = () => {
    setIsCarouselOpen(false);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    numberOfPeople: '',
    date: '',
    message: ''
  });


  useEffect(() => {
    if (isCarouselOpen || isMenuOpen ) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isCarouselOpen, isMenuOpen]);


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setSendM('Envoyer')
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        console.error('FAILED...', err);
        alert('Une erreur est survenue, veuillez réessayer.');
      });
      setSendM('Envoyé')

    // Réinitialiser le formulaire après l'envoi
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      numberOfPeople: '',
      date: '',
      message: ''
    });
  };


  return (
    <div>
        <div className='header'>
            <img src={Logo} className='logo' alt='logo'></img>
            <div className='block-links'>
            <span  className='links'><a rel="noreferrer" target="_blank" href="/">Uber Eats</a></span>
              <span className='links'><a rel="noreferrer" target="_blank" href="/">Deliveroo</a></span>
              <span  className='links'><a rel="noreferrer" target="_blank" href="/">Menu</a></span>

            </div>
 <MenuOutlined className='menu' onClick={() => setIsMenuOpen(true)}/>
             </div>
                    {isMenuOpen && <div className='menu-container'>
                      <div onClick={() => setIsMenuOpen(false)} className='close-menu'><CloseOutlined/></div>
                      <div className='menu-container-mobile'>
                      <div  className='menu-content'><a rel="noreferrer" target="_blank" href="/">Uber Eats</a></div>
                      <div className='menu-content'><a rel="noreferrer" target="_blank" href="/">Deliveroo</a></div>
              <div  className='menu-content'><a rel="noreferrer" target="_blank" href="/">Menu</a></div>
                      </div>
                    </div>}

        <div className='banner' style={{ backgroundImage: `url(${img28})`, backgroundSize:'cover',  backgroundPosition:'center'  }}></div>
        <div className='container'>
            <div className='block-title'>
                <div className='block-title-text'>
      <p className='title'>Bienvenue sur Kissim Thia <br></br>votre traiteur de spécialités africaines</p>
<p className='text-intro'>Découvrez Kissim Thia, votre traiteur de spécialités africaines, qui vous emmène en voyage à travers les saveurs authentiques de l'Afrique. Nous proposons une cuisine variée, mêlant les traditions culinaires de différentes régions africaines, idéale pour tous types d'événements : mariages, anniversaires, événements d'entreprise et plus encore.
  <br></br> <br></br>
  Adaptés à tous les publics, nous offrons également des options végétariennes, véganes et adaptées aux régimes spécifiques. Chaque plat est préparé avec des ingrédients frais et des épices importées pour une expérience culinaire unique. Faites-nous confiance pour sublimer vos moments de partage avec des saveurs exotiques.</p>

                </div>
      <div className='photo-title'  style={{ backgroundImage: `url(${img27})`, backgroundSize:'cover' }} >

      </div>

    

            </div>

      
              <div>
        <p className='subtitle' style={{marginBottom:0}}>Nos services</p>
      </div>

      <div className='service-wrapper'> 
      <div className='service'>
      <img className='review-img' src={menu} alt='quote'></img>
                      <p className='service-title'>Création de menus personnalisés</p>
                <p className='service-text'>{`Nous élaborons des menus sur mesure, en fonction de vos goûts, de vos préférences alimentaires et de vos besoins spécifiques (végétariens, véganes, sans gluten, etc.).`}</p>
              </div>

              <div className='service'>
              <img className='review-img' src={fastdelivery} alt='quote'></img>
                <p className='service-title'>Livraison et service sur place</p>
                <p className='service-text'>Nous pouvons livrer les plats directement sur le lieu de votre événement, ou bien assurer un service complet avec nos équipes de serveurs, que ce soit sous forme de buffet ou à table.</p>
              </div>

              <div className='service'>
              <img className='review-img' src={planning} alt='quote'></img>
                <p className='service-title'>Organisation d’événements clé en main</p>
                <p className='service-text'>{'Que ce soit pour des événements privés comme des mariages, anniversaires, baptêmes ou des événements professionnels tels que des séminaires ou des soirées d’entreprise, nous nous chargeons de tout. Nous gérons aussi la décoration ainsi que la location de matériel (tables, chaises, vaisselle).'}</p>
              </div>

              <div className='service'>
              <img className='review-img' src={catering} alt='quote'></img>
                <p className='service-title'>Buffet ou repas assis</p>
                <p className='service-text'>Que vous préfériez un buffet à volonté, un cocktail dînatoire ou un repas assis avec service à table, nous nous adaptons à vos envies.</p>
              </div>

              <div className='service'>
              <img className='review-img' src={basket} alt='quote'></img>
                <p className='service-title'>Service de nettoyage</p>
                <p className='service-text'>Après votre événement, nous prenons en charge le nettoyage et le rangement du matériel, pour que vous n’ayez à vous soucier de rien.</p>
              </div>

              <div className='service'>
              <img className='review-img' src={wine} alt='quote'></img>
                <p className='service-title'>Consultation et dégustation avant l'événement</p>
                <p className='service-text'>Avant de finaliser votre commande, nous vous proposons une consultation pour discuter des détails et nous organisons une séance de dégustation pour vous permettre de goûter nos plats.</p>
              </div>
              
      
      </div>


      <div>
        <p className='subtitle'>Galerie</p>
      </div>


      <div className="galery">
        {images.map((image, index) => (
          <>
         {index < 8 && <div key={index} className='photo'  onClick={() => openCarousel(index)} style={{ backgroundImage: `url(${image.img})`, backgroundSize:'cover', backgroundPosition:'center' }}>
          </div>}
          </>
          
        ))}
      </div>

      {isCarouselOpen && (
        <Carousel images={images} startIndex={startIndex} onClose={closeCarousel} />
      )}

            <div  className='subtitle'>Témoignages</div>
            <div className='review-wrapper'>
              <div className='review-block'>
                <img className='review-img' src={quote} alt='quote'></img>
                <p className='review'>"Encore un enorme merci pour votre prestation ! Très appréciée dans l'ensemble"</p>
               <p className='review-author'>Laure L.</p>
              </div>

              
              <div className='review-block'>
                <img className='review-img' src={quote} alt='quote'></img>
                <p className='review'>"Merciii beaucoup, comme je vous l'ai dit c'etait excellent"</p>
               <p className='review-author'>Erika F.</p>
              </div>

                     
              <div className='review-block'>
                <img className='review-img' src={quote} alt='quote'></img>
                <p className='review'>"Tout était parfait, très bon"</p>
               <p className='review-author'>Eric P.</p>
              </div>

            </div>


            <div className='block-title'>

                 <div           style={{ backgroundImage: `url(${img1})`, backgroundSize:'cover' }}
 className='photo-title'>
 
      </div>
                <form onSubmit={handleSubmit}>
                <div>
        <p className='subtitle contact'>Nous contacter</p>
      </div>
      <div className='imput-container'>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
          placeholder='Prénom'
        />
     
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
          placeholder='Nom'
        />
        </div> 


      <div className='imput-container'>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          placeholder='Email'
        />

                <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
          placeholder='Téléphone'

        />
      </div>


      <div className='imput-container'>
  <input
          type="number"
          name="numberOfPeople"
          value={formData.numberOfPeople}
          onChange={handleChange}
          placeholder='Nombre de personnes'
        />

<input
  name="date"
  value={formData.date}
  onChange={handleChange}
  type="text"
  placeholder=" jj/mm/aaaa"
/>

      </div>
      <div>
      
      </div>
      <div>
   
      </div>
      <div>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          placeholder='Message'

        ></textarea>
      </div>
      <button className='button-container' type="submit">{sendM}</button>
    </form>
   

    

            </div>
        </div>

        <div className='footer'>
          <div>
          <p style={{textDecoration:'none', WebkitTextDecoration: 'none'}}> <PhoneOutlined className='icon'></PhoneOutlined>06 22 81 68 89</p>
<p style={{textDecoration:'none', WebkitTextDecoration: 'none'}}> <MailOutlined className='icon'></MailOutlined>olga@kissimthia.fr</p>
<p>©2024 Kissim Thia / traiteur de specialités africaines.</p>  
          </div>

          <div className='social'>
          <a rel="noreferrer" target="_blank" href="/"><InstagramFilled className='icon-social'></InstagramFilled></a> 
          <a rel="noreferrer" target="_blank" href="/"><TikTokFilled className='icon-social'></TikTokFilled></a> 

            
          </div>

        </div>
    </div>
  )
}

export default Home